import { IonIcon, IonList, IonItem, IonInput, IonLabel, IonContent, IonPage, IonButton, IonCardContent, IonText, IonNote, IonModal, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
// import { i18nConfig } from '../interfaces/Messages';
import React, { useState, useEffect, useRef } from 'react';
import { getProfile, saveProfile, checkToken, deleteUser, changeEmail } from '../services/api';
import { checkmarkCircleOutline, closeCircleOutline, createOutline } from 'ionicons/icons';
import MlmHeader from '../components/MlmHeader';
import Loader from '../components/Loader';

import { useHistory } from "react-router-dom";
import { getGeolocation } from '../services/api';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import validator from 'validator'
import { OverlayEventDetail } from '@ionic/core/components';


interface IObjectKeys {
  [key: string]: string | number;
}
interface UserProfile extends IObjectKeys {
  fullName: string;
  nickName: string;
  email: string;
  secondaryEmail: string;
  phone: string;
}

const Page: React.FC = () => {

  // const [tr] = useState(JSON.parse(i18nConfig.trans));
  const [profile, setProfile] = useState<UserProfile>();
  const [loading, setLoading] = useState(true);

  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [newEmail, SetNewEmail] = useState<string>('');


  const [userCountry, setuserCountry] = useState();
  const [notValidPhone, setNotValidPhone] = useState(true);
  const [showDeleteMessage, setDeleteMessage] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [messSuccess, setMessSuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  let history = useHistory();
  const domain = `${window.location.protocol}//${window.location.host}`;
  const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
  const [isByRegstrationCode, setIsByRegstrationCode] = useState<boolean>(false);

  function confirm() {

    setLoading(true);


    changeEmail(newEmail)
      .then((response) => {
        console.log('r', response)
        if (response.data.token) {

          modal.current?.dismiss([newEmail, response.data.token], 'confirm');
          if (profile) {
            profile.email = newEmail
          }

        }
        // if (response.data.error) {
        //   setError(response.data.error.message);
        // }

      }).finally(() => {

        setLoading(false);
      });
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {


    setIsOpen(false);
    if (ev.detail.role === 'confirm') {
      //todo set corect success message
      console.log(ev.detail.data);
      alert(`Your Email was change to ${ev.detail.data[0]}!<br>${domain}/confirm/?change_email_token=${ev.detail.data[1]}`);
      // window.location.replace(`${domain}/logout`);//logout user 
    }
    // window.location.href = "/";
  }

  const validateNewEmailEvent = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;
    // setEmailIsValid(undefined);
    if (value === '') return;

    const emailMatchmailIsValid = validateEmail(value);
    if (emailMatchmailIsValid) {
      SetNewEmail(value);
      setEmailIsValid(true)
    } else {
      setEmailIsValid(false);
    }

  };
  const validateEmailEvent = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;

    // setEmailIsValid(false);
    if (value === '') return;
    // setEmailValue(value);
    validateEmail(value) ? setEmailIsValid(true) : setEmailIsValid(false);

    if (profile) {

      profile.secondaryEmail = value;

    }
  };
  const validateEmail = (email: string) => {

    return validator.isEmail(email);

  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    // let data:any = {};
    // for (let i = 0; i < event.target.length -1; i++)
    // {
    //   console.log(event.target[i].name, ":", event.target[i].value)

    //  data[event.target[i].name] = event.target[i].value;

    // }
    // console.log(data);
    // setProfile(data);
    setLoading(true);
    setSuccess(false);

    saveProfile(profile).then((response) => {
      if (response.data.error && response.data.error.code === 400) {
        //todo display error message
        setError(response.data.error.message);
        console.log(response.data.error.message);
      } else {
        console.log(response);
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          const el = document.getElementById("success-animation");

          if (el !== null) {
            el.scrollIntoView();
          }
        }, 100);
      }
    })

  }

  const handlePhoneChange = (phone: any) => {
    console.log(phone);
    if (phone && profile) {
      profile.phone = phone;
      let isValidPhone = isValidPhoneNumber(phone);
      console.log('isValidPhone', isValidPhone);
      setNotValidPhone(isValidPhone);
    }
  }
  //todo remove after request updated
  // const handlenickNameChange = (event:any)=>{
  //   if(profile){
  //     profile.nickName = event.target.value;
  //   }
  // }
  // const handlefullNameChange = (event:any)=>{
  //   console.log(event.target.value);
  //   if(profile){
  //     profile.fullName = event.target.value;
  //   }
  // }
  const handlelast_lnameChange = (event: any) => {
    console.log(event.target.value);
    if (profile) {
      profile.lastName = event.target.value;
    }
  }
  const handlefirst_fnameChange = (event: any) => {
    console.log(event.target.value);
    if (profile) {
      profile.firstName = event.target.value;
    }
  }

  const removeUser = async () => {
    console.log('removeUser')
    let removed: any = await deleteUser();
    console.log('removed', removed);
    if (removed) {

      setMessSuccess(true);
      const timeout = setTimeout(() => {
        // 👇️ redirects to an external URL
        window.location.replace(`${domain}/logout`);
      }, 3000);

      return () => clearTimeout(timeout);

    }
  }

  const fetchProfile = async () => {

    setLoading(true);

    const userProfile: any = await getProfile();
    console.log(userProfile)
    setProfile(userProfile);
    // setIsByRegstrationCode(userProfile.isByRegstrationCode);
    setLoading(false);



  };
  useEffect(() => {
    let tokenIsValid = checkToken();
    if (!tokenIsValid) {

      history.push('/');
    } else {
      fetchProfile();
      getGeolocation()
        .then((response) => {

          if (response && response.YourFuckingCountry) {
            // console.log(response);
            setuserCountry(response.YourFuckingCountryCode);
          }
        })
    }
  }, [])

  return (
    <IonPage>
      <MlmHeader themecolor='black' position='relative' />

      <IonContent fullscreen className='main_content share_screen '>
        <div className="container">
          <h1 className="share_title">Profile</h1>



          {(profile && !loading) &&
            (
              <form onSubmit={handleSubmit}>
                <IonList lines="full" className="white_list">



                  <IonItem className="profile_item">
                    <IonLabel>First Name: </IonLabel>
                    <IonInput name="firstName"
                      value={profile.firstName}
                      onIonChange={(firstName) => handlefirst_fnameChange(firstName)}></IonInput>
                    <IonIcon slot="end" icon={profile.firstName ? checkmarkCircleOutline : closeCircleOutline} />

                  </IonItem>
                  <IonItem className="profile_item">
                    <IonLabel>Last Name: </IonLabel>
                    <IonInput name="lastName"
                      value={profile.lastName}
                      onIonChange={(lastName) => handlelast_lnameChange(lastName)}></IonInput>
                    <IonIcon slot="end" icon={profile.lastName ? checkmarkCircleOutline : closeCircleOutline} />

                  </IonItem>
                  <div className="relative">
                    <IonItem className="profile_item">
                      <IonLabel>Email: </IonLabel>
                      <IonInput className="disabled-input" disabled={true} name='email'
                        value={profile.email}></IonInput>

                    </IonItem>
                    <IonButton className='emailButton' onClick={() => setIsOpen(true)}><IonIcon src='./assets/img/icon/edit.svg' /></IonButton>
                  </div>

                  {(isByRegstrationCode) && (

                    <IonItem className="profile_item">
                      <IonLabel>Secondary Email: </IonLabel>
                      <IonInput name='email'
                        value={profile.secondaryEmail}
                        onIonInput={(event) => validateEmailEvent(event)}

                      ></IonInput>
                      <IonIcon slot="end" icon={profile.secondaryEmail ? checkmarkCircleOutline : closeCircleOutline} />

                    </IonItem>
                  )}
                  <IonItem className="profile_item profile_phone">
                    <IonLabel>Phone:</IonLabel>
                    <PhoneInput
                      name="phoneField"
                      placeholder="Enter phone number"
                      defaultCountry={userCountry}
                      value={profile.phone}
                      onChange={(phone) => handlePhoneChange(phone)}

                    />

                    <IonIcon slot="end" icon={profile.phone ? checkmarkCircleOutline : closeCircleOutline} />

                  </IonItem>
                  {(!notValidPhone) && (
                    <IonLabel color="danger">  Please enter a valid phone number</IonLabel>

                  )}
                </IonList>
                {(!emailIsValid) && (<IonNote slot="helper" color="danger">Invalid email</IonNote>)}

                <div className="bottom-buttons review-bottom-buttons buttons_true">
                  <IonButton className='white-button ' shape="round" size="large" type="submit"  >Save</IonButton>

                  <IonButton slot="end" fill="clear" className="delAccount" shape="round" size="large" onClick={() => { setDeleteMessage(true) }} >Delete My Account</IonButton>


                </div>
              </form>
            )
          }

          <div className='ion-padding'>
            {(error) && (
              <p >{error}</p>
            )}
            {(messSuccess) && (
              <p className='success'>
                <IonIcon icon="./assets/img/icon/thumb.svg" />
                <strong>&nbsp; your account was deleted and you will be redirect to the home page in 3 sec</strong>
              </p>
            )}
          </div>

          <div className={(showDeleteMessage) ? 'sound-card-overlay review-card-overlay sound-card-overlay-active' : 'sound-card-overlay review-card-overlay'} id="messagesEndRef" >
            <div className='sound-card'>

              <IonCardContent>
                <IonText>Are you sure you want to delete your account? <br />
                  All your data will be deleted. The operation is final and cannot be undone. </IonText>
              </IonCardContent>

              <IonButton slot="start" fill="solid" shape="round" className="black_button" onClick={() => { removeUser(); setDeleteMessage(false) }} >
                Remove
              </IonButton>
              <IonButton fill="clear" slot="end" onClick={() => { setDeleteMessage(false) }}>Cancel</IonButton>

            </div>
          </div>

          <Loader isloading={loading} />

          {(success) && (

            <div className="success-animation" id="success-animation">
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
            </div>
          )}



          <IonModal id="example-modal" className="email-modal" ref={modal} isOpen={isOpen} onWillDismiss={(ev) => onWillDismiss(ev)}>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => modal.current?.dismiss()} color="light">Cancel</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">

              <h2>Change email address</h2>


              <form className="smallForm" >
                <Loader isloading={loading} />
                <IonItem className="contact_item">
                  <IonLabel>New Email: </IonLabel>
                  <IonInput name='email'
                    value={newEmail}
                    onIonInput={(event) => validateNewEmailEvent(event)}
                  ></IonInput>
                  {(!emailIsValid) && (<IonNote slot="helper" color="danger">Invalid email</IonNote>)}
                </IonItem>
                {(newEmail) && (
                  <IonButton className='white-button ' shape="round" strong={true} disabled={loading} onClick={() => confirm()}>
                    Add email
                  </IonButton>
                )}


                {(error) && (
                  <p className='errormessage'>  <IonNote color="danger">{error}</IonNote></p>
                )}

              </form>


            </IonContent>


          </IonModal>



        </div>
      </IonContent>
    </IonPage >

  );
};

export default Page;
