import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonNote,
  IonIcon
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { locale, i18nConfig } from '../interfaces/Messages';
import { Link, useLocation } from 'react-router-dom';
import { User, SharedContact } from '../interfaces/User';
import { getToken, sendResetPassword } from '../services/api';
import Loader from '../components/Loader';


type Props = {
  stateChanger: any,
  contact?: SharedContact,
}

const Login: React.FC<Props> = ({ stateChanger, contact }): JSX.Element => {
  const [language, setLang] = useState(locale);
  const [tr] = useState(JSON.parse(i18nConfig.trans));

  const [user, setUser] = useState<User>();

  const [emailValue, setEmailValue] = useState<string>('');
  const [emailIsValid, setEmailIsValid] = useState<boolean>();
  const [resetPass, setPass] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [authNeeded, setAuth] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [isShown, setIsSHown] = useState(false);


  const validateEmail = (email: string) => {
    return email.match(
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    );
  };

  const validateEmailEvent = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;
    setEmailIsValid(undefined);
    if (value === '') return;
    setEmailValue(value);
    validateEmail(value) !== null ? setEmailIsValid(true) : setEmailIsValid(false);

  };






  const loginSubmit = (data: any) => {
    setLoading(true);
    data.preventDefault();
    // console.log('login sent with: ', data);
    getToken(data)
      .then((response) => {
        console.log('response');
        console.log(response);

        if (response.data.error) {
          //todo display error message
          console.log(response.data.error.message);
          setError(response.data.error.message);
        } else {
          // Store the new user data 
          console.log(response.data.access_token);
          const res = JSON.stringify(response);
          localStorage.removeItem('user');
          localStorage.setItem('user', res);
          localStorage.setItem('isCreator', response.data.isCreator);
          setUser(response.data);
          localStorage.removeItem('access_token');
          localStorage.setItem('access_token', response.data.access_token);
          setExpirationToken(response.data.expires_in);
          setAuth(false);
          stateChanger(false);
          // window.location.reload();
        }


      }).finally(() => {
        setLoading(false);
      });
  }
  const domain = `${window.location.protocol}//${window.location.host}`;
  const emailSubmit = (data: any) => {
    setLoading(true);
    data.preventDefault();
    const d = {
      "domain": domain,
      "email": data.target['email'].value,
    }
    sendResetPassword(d)
      .then((response) => {
        console.log('response');
        console.log(response);

        if (response.data.error) {
          //todo display error message
          console.log(response.data.error.message);
          setError(response.data.error.message);
        } else {
          console.log('success')
          setSuccess(true)
        }


      }).finally(() => {
        setLoading(false);
      });

  }
  const search = useLocation().search;

  const setExpirationToken = (expiration: string) => {
    localStorage.removeItem('expiration_token');
    let now = Math.floor(Date.now() / 1000);
    let endToken = now + parseFloat(expiration);
    console.log(now, endToken);
    localStorage.setItem('expiration_token', endToken.toString());
  }

  useEffect(() => {
    if (contact?.sharedType === "email" && contact.value !== "") {
      setEmailIsValid(true)
    }
    const key = window.location.hash;
    console.log('key', key);
    if (key === "#forgotpassword") {
      setPass(true);
    }

  }, []);

  return (

    <div>
      {(!resetPass) && (
        <>
          <h1 className='ion-text-center'>Login</h1>
          <form className="smallForm" onSubmit={loginSubmit}>


            <IonItem className="login_item">
              <IonLabel>{tr.email}</IonLabel>
              <IonInput type="email" name='email'

                onIonInput={(event) => validateEmailEvent(event)}
                required
                value={(contact?.sharedType === "email" ? contact.value : emailValue)}
                disabled={(contact?.sharedType === "email") ? true : false}

              ></IonInput>
              {!emailIsValid && emailValue && (<IonNote slot="helper" color="warning">Invalid email</IonNote>)}

            </IonItem>
            <IonItem className="login_item">
              <IonLabel>{tr.password}</IonLabel>
              <IonInput type={isShown ? "text" : "password"} required name="password" clearOnEdit={false} />
              <IonIcon slot="end" className="passIcon" onClick={() => setIsSHown(!isShown)} icon={isShown ? "./assets/img/icon/eye.svg" : "./assets/img/icon/eye-outline.svg"}  ></IonIcon>
            </IonItem>
            <IonButton expand="block" size="large" shape="round" className='yellow_button' type="submit" disabled={!emailIsValid}>{tr.login}</IonButton>


          </form>
        </>
      )}

      {(resetPass) && (
        <><h1 className='ion-text-center'>Reset Password</h1>
          <form className="smallForm" onSubmit={emailSubmit}>


            <IonItem className="login_item">
              <IonLabel>{tr.email}</IonLabel>
              <IonInput type="email" name='email'

                onIonInput={(event) => validateEmailEvent(event)}
                required
                value={emailValue}


              ></IonInput>
              {!emailIsValid && emailValue && (<IonNote slot="helper" color="warning">Invalid email</IonNote>)}

            </IonItem>
            <IonButton expand="block" size="large" shape="round" className='yellow_button' type="submit" disabled={!emailIsValid}>Reset password</IonButton>


          </form>
          {(success) && (
            <p className="ion-text-center register_link">
              <IonIcon icon="./assets/img/icon/thumb.svg" />
              &nbsp;An email with a reset password link has been sent to your email. <br />Please remember to check your spam folder. If you don't find it there, please verify the email address you provided.
            </p>
          )}
        </>
      )}

      <Loader isloading={loading} />
      {(!resetPass) && (
        <p className="ion-text-center register_link">{tr.dont_have_account}
          <Link to={{ pathname: '/register' }}>{tr.register}</Link>
          <br />
          Forgot password? <a href="#forgotpassword" onClick={() => { setPass(true) }}>Reset</a>
        </p>
      )}
      {(resetPass) && (
        <p className="ion-text-center register_link"><a href="#backtologin" onClick={() => setPass(false)}>Back to Login</a>
        </p>
      )}
      <p >
        {(error) && (
          <p className="ion-text-center">{error}</p>
        )}
      </p>
    </div>

  );
};

export default Login;
